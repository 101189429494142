















































































import { Component, Inject, Vue } from 'vue-property-decorator';
import { AppStore } from '@/store/types/AppStore';
import { ApiFacade } from '@/services/ApiFacade';
import { Course } from '@/models/entities/Course';
import { VForm } from '@/types/VForm';
import { requiredValidator } from '@/services/defaultFormValidators';
import LoadingContent from '@/components/LoadingContent.vue';
import cloneDeep from 'lodash/cloneDeep';
import { CourseStatus } from '@/types/Statuses';
import { HydraError } from '@/api/types/HydraTypes';
import ErrorAlert from '@/components/ErrorAlert.vue';
import { NotificationsService } from '@/services/NotificationService';

type CourseForm = Pick<Course, 'courseName' | 'polygon' | 'status'>;

interface CourseEditFormView extends Vue {
  $refs: {
    form: VForm;
  };
}

@Component({
  components: {
    Error: ErrorAlert,
    LoadingContent,
  },
})
class CourseEditFormView extends Vue {
  @Inject() appStore!: AppStore;
  @Inject() apiFacade!: ApiFacade;
  @Inject() notify!: NotificationsService;

  isValid: boolean = false;
  isLoading: boolean = false;
  isInProcess: boolean = false;

  course: Course | null = null;
  CourseStatus = CourseStatus;

  form: Partial<CourseForm> = {
    courseName: '',
    polygon: undefined,
    status: CourseStatus.active,
  };
  rules = {
    requiredValidator,
  };

  error: HydraError | Error | string | null = null;

  get formTitle() {
    return this.isNew
      ? 'Добавить курс'
      : 'Редактирование курса';
  }

  get polygons() {
    return this.appStore.getters.availablePolygons;
  }

  get isNew() {
    return !this.$route?.params?.courseId;
  }

  async mounted() {
    if (this.$route.params.courseId) {
      try {
        this.isLoading = true;
        const course = await this.apiFacade.fetchCourse(parseInt(this.$route.params.courseId, 10));
        if (!course) {
          throw new Error('The course not found');
        }
        this.course = course;
        this.form = {
          polygon: course.polygon,
          courseName: course.courseName,
          status: course.status,
        };
      } catch (err) {
        this.error = err;
        console.error('Course is not found', err);
      } finally {
        this.isLoading = false;
      }
    }
  }

  close() {
    this.$emit('close');
    // this.$router.push({ name: 'courses' });
  }

  handleCancel() {
    this.close();
  }

  handleDelete() {
    // const newCourse:Course = Object.assign(cloneDeep(this.course), this.form);
    // newCourse.status = CourseStatus.
    // return this.form
  }

  async handleSave() {
    this.error = null;
    if (!this.$refs.form.validate()) {
      return;
    }

    try {
      this.isInProcess = true;
      let newCourse;

      if (this.isNew) {
        const course = Course.createFromDto({
          courseName: this.form.courseName,
          polygon: this.form.polygon,
        });
        newCourse = await this.apiFacade.createCourse(course);
      } else {
        const course = Object.assign(cloneDeep(this.course), this.form);
        newCourse = await this.apiFacade.updateCourse(course);
      }

      this.$emit('course-updated', newCourse);
      this.close();
    } catch (err) {
      console.error('Course adding error', err);
      this.error = err;
    } finally {
      this.isInProcess = false;
    }
  }
}

export default CourseEditFormView;
